import Vue from 'vue';
import Router from 'vue-router';

Vue.use(Router);

const routes = [
    {
        path: '*',
        redirect: '/home'
    },
    {
        name: 'home',
        component: () => import('./views/home'),
        meta: {
            title: '首页'
        }
    },
    {
        name: 'my',
        component: () => import('./views/my'),
        meta: {
            title: '我的'
        }
    },
    {
        name: 'register',
        component: () => import('./views/register'),
        meta: {
            title: '注册'
        }
    },
    {
        name: 'login',
        component: () => import('./views/login'),
        meta: {
            title: '登录'
        }
    },
    {
        name: 'reserve/pub',
        component: () => import('./views/reserve/pub'),
        meta: {
            title: '发布广告'
        }
    },
    {
        name: 'reserve/update',
        component: () => import('./views/reserve/update'),
        meta: {
            title: '更改预约'
        }
    },
    {
        name: 'reserve/my-reserve',
        component: () => import('./views/reserve/my-reserve'),
        meta: {
            title: '我的广告'
        }
    },
    {
        name: 'my/user-edit',
        component: () => import('./views/my/user-edit'),
        meta: {
            title: '我的账号'
        }
    },
];

// add route path
routes.forEach(route => {
    route.path = route.path || '/' + (route.name || '');
});

const router = new Router({ routes });

router.beforeEach((to, from, next) => {
    const title = to.meta && to.meta.title;
    if (title) {
        document.title = title;
    }
    next();
});

export {
    router
};