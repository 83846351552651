import Vue from 'vue'
import App from './App.vue'

import * as Sentry from "@sentry/vue";
import { Integrations } from "@sentry/tracing";
import browserUtil from "common/utils/browser-util"
import { Toast, Dialog, NavBar, Popup, Lazyload } from 'vant';
import common from 'common'
import { router } from './router';

if (!browserUtil.isMobile()) {
    import('@vant/touch-emulator');
}

Vue.config.productionTip = false

Sentry.init({
    Vue: Vue,
    dsn: "https://cdba00bd98cf45089af021758189eac9@sentry.mondol.info/10",
    autoSessionTracking: true,
    tracingOptions: {
        trackComponents: true,
    },
    integrations: [
        new Integrations.BrowserTracing({
            routingInstrumentation: Sentry.vueRouterInstrumentation(router),
            // tracingOrigins: ["localhost", "my-site-url.com", /^\//],
        }),
    ],

    // We recommend adjusting this value in production, or using tracesSampler
    // for finer control
    tracesSampleRate: 1.0,
    release: process.env.npm_package_version
});
// Sentry.setUser({ id: fileConfig.shopId })


Vue.use(Toast)
Vue.use(Dialog)
Vue.use(NavBar)
Vue.use(Popup)
Vue.use(Lazyload, { lazyComponent: true });

let vueProps = common.init({
    vue: Vue,
    message: {
        show: opts => Toast(opts)
    }
})

new Vue({
    ...vueProps,
    router,
    render: h => h(App),
}).$mount('#app')
