<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
export default {
  name: "App",
  mounted() {
    this.$recaptchaLoaded().then(() => {
      this.$recaptchaInstance.hideBadge();
    });
  },
};
</script>

<style lang="less">
html,
body {
  height: 100%;
  -webkit-tap-highlight-color: transparent;
}

body {
  font-family: -apple-system-font, Helvetica Neue, Helvetica, sans-serif;
  background-color: #f7f8fa;
  -webkit-font-smoothing: antialiased;
}

.block-title {
  margin: 1rem 0 0.5rem 0.5rem;
  color: #455a6499;
  font-weight: normal;
  font-size: 0.8rem;
}

.ad-card-box {
  margin: 0.5rem 0.4rem;
  background-color: white;
  border-radius: 0.5rem;
  overflow: hidden;

  .spec-tag {
    margin-right: 0.2rem;
    margin-top: 0.2rem;
  }

  .card-item {
    background-color: white;
    padding: 0;
    padding-right: 0.5rem;

    .van-card__title {
      font-size: 0.9rem;
      font-weight: bold;
      margin: 0.3rem 0;
      margin-top: 0.4rem;
      line-height: initial;
      max-height: initial;
    }

    .van-card__thumb {
      width: 176px;
      height: 88px;

      .van-image {
        width: 100%;
        height: 100%;
      }
    }

    .van-card__desc {
      overflow: initial;
      white-space: initial;
      max-height: inherit;
    }

    .van-card__footer {
      padding-bottom: .2rem;
    }
  }
}
</style>
